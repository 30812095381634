import { useCompanyContext } from '../../context/CompanyContext';
import { JobCostBudget } from '../../Interfaces/Accounting/JobCostBudget.interface';

type jobCostBudgetReportHeaderProps = {
  jobCost: JobCostBudget;
};

const JobCostBudgetReportHeader = ({
  jobCost,
}: jobCostBudgetReportHeaderProps) => {
  const { selectedCompany } = useCompanyContext();

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Job Cost Project Budget</div>
      <div className="text-17px ml-3 mt-2">
        <div>
          <span className="font-bold mr-1">Period:</span>
          {`${jobCost.periodStart ?? ''}${
            jobCost.periodEnd ? ` - ${jobCost.periodEnd}` : ''
          }`}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default JobCostBudgetReportHeader;
