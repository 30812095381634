import { useQuery } from '@tanstack/react-query';
import { DropdownChangeParams } from 'primereact/dropdown';
import DropdownStyled from '../../../components/inputs/StyledInputs/DropdownStyled';
import { useCompanyContext } from '../../../context/CompanyContext';
import { JCJobSummary } from '../../../Interfaces/Accounting/JobsContracts.interface';
import { fecthJobs } from '../../../services/JobsContractsService';

type JobInputProps = {
  value: string;
  onChange: (e: DropdownChangeParams) => void;
};

const JobInput = ({ value, onChange }: JobInputProps) => {
  const { selectedCompany } = useCompanyContext();
  const jobs = useQuery({
    queryKey: ['getPRJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const jobItems =
    jobs.data?.map((job: JCJobSummary) => {
      return { ...job, label: `${job.Job} ${job.Description}` };
    }) ?? [];
  jobItems?.unshift({ label: 'N/A', Job: 'N/A' });
  const selectedInfo = jobItems.find((job: JCJobSummary) => job.Job === value);

  return (
    <span className="w-full h-full">
      <DropdownStyled
        options={jobItems}
        labelField="label"
        valueField="Job"
        isLoading={jobs.isLoading}
        error={jobs.isError}
        className="h-full printHide"
        filter={true}
        value={value}
        onChange={onChange}
        absoluteLabel={true}
      />
      <span className="printMedia printShow">{selectedInfo?.label}</span>
    </span>
  );
};

export default JobInput;
