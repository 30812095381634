import {
  DataTable,
  DataTableProps,
  DataTableRowClassNameOptions,
  DataTableRowExpansionTemplate,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import React, { useCallback, useRef, useState } from 'react';
import MultipleSelectStyled from '../inputs/StyledInputs/MultipleSelectStyled';
import { compareReactElements } from 'apps/tmr-frontend/src/utils/utils';
import RowSelection from './RowSelection';
import TableContextMenu from './TableContextMenu';
import useHideColumns from '../../hooks/useHideColumns';
import { MultiSelectChangeParams } from 'primereact/multiselect';

type TableProps = DataTableProps & {
  children: React.ReactNode;
  data: unknown[];
  className?: string;
  calcHeight?: (rows: number) => number;
  rowClass?: (
    data: unknown,
    options?: DataTableRowClassNameOptions
  ) => object | string;
  onRowsAmountChange?: (rows: number) => void;
  defaultRowAmount?: number;
  fixedDefaultHeight?: number;
  selectionKey?: string;
  hideColumns?: boolean;
  nonHideableColumns?: string[];
  alwaysHiddenColumns?: string[];
  customRowExpansionTemplate?: (
    data: unknown,
    options: DataTableRowExpansionTemplate,
    hiddenColumns?: string[]
  ) => React.ReactNode;
  hideColumnsDropdown?: boolean;
  persistHiddenColumns?: boolean;
  headerContent?: React.ReactNode;
  afterHiddenColumnsUpdate?: (
    showColumns: string[],
    columnOptions: string[]
  ) => void;
  columnsDefault?: {
    showColumns: string[];
    columnOptions: string[];
  };
};

const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (
    {
      id,
      children,
      data,
      className,
      calcHeight,
      rowClass,
      onRowsAmountChange,
      defaultRowAmount,
      fixedDefaultHeight,
      selectionKey,
      hideColumns,
      headerColumnGroup,
      nonHideableColumns,
      alwaysHiddenColumns,
      rowExpansionTemplate,
      customRowExpansionTemplate,
      hideColumnsDropdown,
      persistHiddenColumns,
      headerContent,
      afterHiddenColumnsUpdate,
      columnsDefault,
      ...otherProps
    },
    ref
  ) => {
    const [showRows, setShowRows] = useState(
      defaultRowAmount ? defaultRowAmount : 15
    );
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>(
      {}
    );
    const {
      columnOptions,
      columns,
      headerColumns,
      showColumns,
      updateVisibleColumns,
      hideColumn,
    } = useHideColumns({
      children,
      id,
      persistHiddenColumns,
      alwaysHiddenColumns,
      nonHideableColumns,
      headerColumnGroup,
      afterColumnsUpdate: afterHiddenColumnsUpdate,
      defaultValues: columnsDefault,
    });
    const dt = useRef<DataTable>(null);

    const selectRow = useCallback(
      (e: DataTableSelectionChangeParams) => {
        const key = selectionKey as string;
        const isSelected = selectedRows[e.value[key]];
        selectedRows[e.value[key]] = true;
        if (isSelected) {
          selectedRows[e.value[key]] = false;
        }
        setSelectedRows({ ...selectedRows });
      },
      [selectedRows, selectionKey]
    );

    const onVisibleColumnChange = (e: MultiSelectChangeParams) => {
      updateVisibleColumns(e.value);
    };

    return (
      <React.Fragment>
        {((hideColumns && !hideColumnsDropdown) ||
          calcHeight ||
          headerContent) && (
          <div className="printHide flex flex-wrap  mx-3 mb-2 mt-3 gap-2">
            {hideColumns && !hideColumnsDropdown && (
              <div className="max-w-7 w-7">
                <MultipleSelectStyled
                  resetFilterOnHide={true}
                  showSelectAll={true}
                  filter={true}
                  options={columnOptions}
                  placeholder={'Select columns to show'}
                  value={showColumns}
                  onChange={onVisibleColumnChange}
                  display="chip"
                />
              </div>
            )}
            {headerContent}
            {calcHeight && (
              <RowSelection
                showRows={showRows}
                setShowRows={setShowRows}
                onRowsAmountChange={onRowsAmountChange}
              />
            )}
          </div>
        )}
        {hideColumns && (
          <TableContextMenu
            dt={dt}
            afterContextMenuSelected={hideColumn}
            noMenuColumns={nonHideableColumns}
          />
        )}
        <DataTable
          id={id}
          ref={dt}
          rows={showRows}
          removableSort
          value={data}
          scrollable
          scrollHeight={`${
            calcHeight ? calcHeight(showRows) : fixedDefaultHeight ?? 400
          }px`}
          className={`tableStyled tablePrint ${className}`}
          stripedRows
          rowClassName={rowClass}
          onSelectionChange={selectionKey ? selectRow : undefined}
          selection={selectedRows}
          headerColumnGroup={headerColumns}
          rowExpansionTemplate={(data, options) => {
            if (customRowExpansionTemplate) {
              const hiddenCols = columnOptions.filter(
                (header) => !showColumns.includes(header)
              );

              return customRowExpansionTemplate(data, options, hiddenCols);
            }

            if (rowExpansionTemplate) {
              return rowExpansionTemplate(data, options);
            }

            return null;
          }}
          {...otherProps}
        >
          {hideColumns ? columns : children}
        </DataTable>
      </React.Fragment>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<TableProps>,
  nextTransactions: Readonly<TableProps>
) => {
  let isEquals = true;
  Object.keys(prevTransactions).forEach(function (key) {
    if (
      prevTransactions[key as keyof TableProps] !==
        nextTransactions[key as keyof TableProps] &&
      key !== 'children'
    ) {
      isEquals = false;
    }
  });

  const prevChildren = prevTransactions.children;
  const nextChildren = nextTransactions.children;

  if (Array.isArray(prevChildren) && Array.isArray(nextChildren)) {
    if (prevChildren.length !== nextChildren.length) {
      isEquals = false;
    } else {
      for (let i = 0; i < prevChildren.length; i++) {
        const prevChild = prevChildren[i];
        const nextChild = nextChildren[i];

        if (
          React.isValidElement(prevChild) &&
          React.isValidElement(nextChild)
        ) {
          isEquals = isEquals && compareReactElements(prevChild, nextChild);
        } else {
          isEquals = isEquals && prevChild === nextChild;
        }
      }
    }
  } else {
    isEquals = isEquals && prevChildren === nextChildren;
  }

  return isEquals;
};

export default React.memo(Table, transactionsAreEqual);
