import axios from 'axios';
import { auth } from '../../firebase';

const apiUrl = process.env['NX_API_URL'];

export const fecthLatestJobCostBudget = async (
  companyID: string,
  source: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/jobCostBudget/latest`, {
    signal,
    params: { companyID, source },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};
