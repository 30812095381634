import { Column } from 'primereact/column';
import {
  DataTableExpandedRows,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import React, { useCallback, useState } from 'react';
import Table from '../../../components/Table/Table';
import { animateCellText } from 'apps/tmr-frontend/src/utils/htmlUtils';
import { useCompanyContext } from '../../../context/CompanyContext';
import ExpandIcon from '../PayrollTimesheet/ExpandIcon';
import PCReimbursementTableDetails from './PCReimbursementTableDetails';
import {
  Employee as EmployeeType,
  EmployeePCReimbursement,
  PCReimbursementDetails,
} from '../../../Interfaces/Accounting/Payroll.interface';
import { FILE_20_MB_LIMIT } from 'apps/tmr-frontend/src/utils/fileConsts';
import { getFilesTotalSize } from 'apps/tmr-frontend/src/utils/fileUtil';
import Job from './Fields/Job';
import Phase from './Fields/Phase';
import GLAccount from './Fields/GLAccount';
import PCReimbursementFile from './Fields/PCReimbursementFile';
import Employee from './Fields/Employee';
import { useQuery } from '@tanstack/react-query';
import { fetchPREmployeeList } from '../../../services/PayrollService';
import Amount from './Fields/Amount';
import {
  addNewDetail,
  findEmployee,
  removeDetail,
  updateDetails,
} from './utils';

type PCReimbursementTableProps = {
  employees: EmployeePCReimbursement[];
  draftID?: string;
  sizeLimit: number;
  showErrors: boolean;
  changeEmployees: (
    data:
      | EmployeePCReimbursement[]
      | ((list: EmployeePCReimbursement[]) => EmployeePCReimbursement[]),
    ignoreTemp?: boolean
  ) => void;
  changeFileLimit: (data: (size: number) => number) => void;
  searchBonus?: boolean;
};

const PCReimbursementTable = ({
  employees,
  draftID,
  sizeLimit,
  showErrors,
  changeEmployees,
  changeFileLimit,
  searchBonus,
}: PCReimbursementTableProps) => {
  const { selectedCompany } = useCompanyContext();
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  useQuery({
    queryKey: ['getPRActiveEmployees', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchPREmployeeList(
        { companyID: selectedCompany!.id, status: true },
        signal
      );
    },
    onSuccess: (data) => {
      const empJson = (data as (EmployeeType & { disabled: true })[]).reduce(
        (acc, emp) => {
          acc[emp.EmpID] = emp;
          return acc;
        },
        {} as Record<number, EmployeeType & { disabled: true }>
      );

      changeEmployees((emps) => {
        const newEmps = emps.map((employee) => {
          const empData = empJson[employee.empID ?? 0];

          if (empData) {
            empData.disabled = true;
            employee.firstName = empData.FirstName;
            employee.lastName = empData.LastName;
          }

          return employee;
        });

        return newEmps;
      }, true);
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
  });

  const updateEmployeeDetails = (
    empID: number,
    detailIndex: number,
    detail: PCReimbursementDetails
  ) => {
    const searchResult = findEmployee(employees, empID);

    if (!searchResult) {
      return;
    }

    const newEmp = updateDetails(searchResult.employee, detailIndex, detail);

    if (!newEmp) {
      return;
    }

    const empList = [...employees];
    empList[searchResult.index] = newEmp;

    changeEmployees(empList);
  };

  const addNewEmployeeDetail = (empID: number) => {
    const searchResult = findEmployee(employees, empID);

    if (!searchResult) {
      return;
    }

    const newEmp = addNewDetail(searchResult.employee);

    if (!newEmp) {
      return;
    }

    const empList = [...employees];
    empList[searchResult.index] = newEmp;

    changeEmployees(empList);
  };

  const removeEmployeeDetail = (empID: number, detailIndex: number) => {
    const searchResult = findEmployee(employees, empID);

    if (!searchResult) {
      return;
    }

    const newEmp = removeDetail(searchResult.employee, detailIndex);

    if (!newEmp) {
      return;
    }

    const empList = [...employees];
    empList[searchResult.index] = newEmp;

    changeEmployees(empList);
  };

  const rowExpansionTemplate = (data: EmployeePCReimbursement) => {
    return (
      <PCReimbursementTableDetails
        details={data.details ?? []}
        empID={data.empID!}
        addNewDetail={addNewEmployeeDetail}
        removeDetail={removeEmployeeDetail}
        updateDetails={updateEmployeeDetails}
        showErrors={showErrors}
        searchBonus={searchBonus}
      />
    );
  };

  const getRowClassname = (data: EmployeePCReimbursement) => {
    if (
      !data.empID &&
      !data.total &&
      !data.job &&
      !data.phaseCode &&
      !data.overHead
    ) {
      return 'printHide';
    }

    return '';
  };

  let total = 0;
  employees.forEach((data: EmployeePCReimbursement) => {
    total += data.total ?? 0;
  });

  return (
    <React.Fragment>
      <Table
        id="pr-petty-cash-reimbursement"
        data={employees}
        dataKey="empID"
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        className={`mx-3 dashboardOptionShadow with-expanded noPadding noHover`}
        onRowMouseEnter={useCallback(onRowHover, [])}
        fixedDefaultHeight={790}
        rowClassName={useCallback(getRowClassname, [])}
      >
        <Column
          field="empID"
          header="Emp ID"
          body={(row, options) => {
            return (
              <Employee
                value={row.empID}
                disableFetch={true}
                changeValue={(empID, firstName, lastName) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...row };

                    if (!empID) {
                      delete rowCopy.empID;
                      delete rowCopy.firstName;
                      delete rowCopy.lastName;
                    } else {
                      rowCopy.empID = empID;
                      rowCopy.firstName = firstName;
                      rowCopy.lastName = lastName;
                    }

                    data[options.rowIndex] = rowCopy;

                    if (empID && options.rowIndex === data.length - 1) {
                      data.push({ empID: null, total: null, details: [{}] });
                    }

                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal text-center"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="lastName"
          header="Last Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.lastName}</div>;
          }}
          style={{ minWidth: '180px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className=" text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="firstName"
          header="First Name"
          body={(pr) => {
            return <div className="scroll-text">{pr.firstName}</div>;
          }}
          style={{ minWidth: '160px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Total"
          sortable
        />
        <Column
          field="total"
          header="Total Amount"
          body={(record) => {
            if (record.total === undefined || record.total === null) {
              return;
            }

            return record.total;
          }}
          style={{ minWidth: '130px' }}
          headerClassName="tableHeader font-normal"
          className="text-standard blackText tableCell justify-content-center text-center"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
          footer={total}
          sortable
        />
        <Column
          field="amount"
          header="Amount"
          body={(row, options) => {
            if (expandedRows[row.empID]) {
              return;
            }

            if (row.useDetails) {
              return (
                <div
                  className="mx-auto printHide totalColor hover:underline cursor-pointer"
                  onClick={() => {
                    setExpandedRows((rows) => {
                      rows[row.empID] = true;

                      return { ...rows };
                    });
                  }}
                >
                  Expand for details
                </div>
              );
            }

            return (
              <Amount
                value={row.amount}
                disabled={row.useDetails}
                changeValue={(amount) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...row };

                    if (amount) {
                      rowCopy.amount = amount;
                      rowCopy.total = amount;
                    } else {
                      delete rowCopy.amount;
                      delete rowCopy.total;
                    }

                    data[options.rowIndex] = rowCopy;

                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '100px', minHeight: '54px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="job"
          header="Job Number"
          body={(row, options) => {
            if (row.useDetails) {
              return;
            }

            return (
              <Job
                value={row.job}
                disableFetch={!!options.rowIndex}
                showErrors={
                  showErrors && row.empID && !(row.job || row.overHead)
                }
                disabled={!!row.overHead || row.useDetails}
                changeValue={(job) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...row };
                    rowCopy.job = job;
                    delete rowCopy.phaseCode;
                    data[options.rowIndex] = rowCopy;

                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="phaseCode"
          header="Phase Code"
          body={(row, options) => {
            const job = row.job;

            if (!job) {
              return;
            }

            return (
              <Phase
                searchBonus={searchBonus}
                value={row.phaseCode}
                job={row.job}
                showErrors={
                  showErrors && row.empID && row.job && !row.phaseCode
                }
                changeValue={(phase) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...row };
                    rowCopy.phaseCode = phase;
                    data[options.rowIndex] = rowCopy;

                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          field="overHead"
          header="Overhead"
          body={(row, options) => {
            if (row.useDetails) {
              return;
            }

            return (
              <GLAccount
                searchBonus={searchBonus}
                value={row.overHead}
                disableFetch={!!options.rowIndex}
                disabled={!!row.job || row.useDetails}
                showErrors={
                  showErrors && row.empID && !(row.job || row.overHead)
                }
                changeValue={(overHead) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...row };
                    rowCopy.overHead = overHead;
                    data[options.rowIndex] = rowCopy;

                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="text-standard blackText tableCell"
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            return (
              <PCReimbursementFile
                useBonus={searchBonus}
                prevAttachments={record.attachments}
                attachments={record.newAttachments}
                defaultRemovedAttachments={record.deleteAttachments}
                totalLimit={sizeLimit}
                draftID={draftID}
                empID={record.empID}
                onChange={(files) => {
                  changeEmployees((data) => {
                    const rowCopy = { ...record };
                    const size = getFilesTotalSize(
                      rowCopy.newAttachments ?? []
                    );

                    if (!files?.length) {
                      changeFileLimit((amount) => {
                        return amount + size;
                      });
                      delete rowCopy.newAttachments;
                    } else {
                      rowCopy.newAttachments = files;
                      changeFileLimit((amount) => {
                        return amount + size - getFilesTotalSize(files);
                      });
                    }

                    data[options.rowIndex] = rowCopy;
                    return [...data];
                  });
                }}
                deleteAttachment={(fileName: string, size: number) => {
                  changeFileLimit((amount) => {
                    return amount + size;
                  });

                  changeEmployees((data) => {
                    const rowCopy = { ...record };

                    if (rowCopy.deleteAttachments) {
                      rowCopy.deleteAttachments.push(fileName);
                    } else {
                      rowCopy.deleteAttachments = [fileName];
                    }

                    data[options.rowIndex] = rowCopy;
                    return [...data];
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '30px', maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            if (!record.empID) {
              return;
            }

            return (
              <ExpandIcon
                expanded={expandedRows[record.empID]}
                onOpen={() => {
                  setExpandedRows((rows) => {
                    rows[record.empID] = true;

                    return { ...rows };
                  });
                }}
                onClose={() => {
                  setExpandedRows((rows) => {
                    delete rows[record.empID];

                    return { ...rows };
                  });
                }}
              />
            );
          }}
          style={{ minWidth: '30px', maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
        <Column
          body={(record, options) => {
            if (
              employees.length === 1 ||
              employees.length === options.rowIndex + 1
            ) {
              return;
            }

            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  changeEmployees((data) => {
                    const newData = data.filter(
                      (data, index) => options.rowIndex !== index
                    );

                    return newData;
                  });
                }}
              />
            );
          }}
          style={{ maxWidth: '30px' }}
          headerClassName={`tableHeader font-normal`}
          className={`text-standard blackText printHide tableCell justify-content-center`}
          footerClassName="tableFooter"
        />
      </Table>
    </React.Fragment>
  );
};

export default PCReimbursementTable;
