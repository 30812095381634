import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getNewHeaderGroup, getText } from '../../utils/tableUtils';

const getHiddenColumns = (columnOptions: string[], showColumns: string[]) => {
  const hiddenCols = columnOptions.filter(
    (header) => !showColumns.includes(header)
  );

  return hiddenCols;
};

const getColumnsData = (
  children: React.ReactNode,
  alwaysHiddenColumns?: string[],
  nonHideableColumns?: string[],
  hiddenCols?: string[]
) => {
  if (!Array.isArray(children)) {
    return { columns: [], showColumns: [], columnOptions: [] };
  }

  const data: {
    columns: React.ReactNode;
    showColumns: string[];
    columnOptions: string[];
  } = children.reduce(
    (acc, child) => {
      if (!child) {
        return acc;
      }

      const header = child.props?.header;
      const headerTitle = getText(header ?? '');

      if (alwaysHiddenColumns?.includes(headerTitle)) {
        return acc;
      }

      if (nonHideableColumns?.includes(headerTitle)) {
        acc.columns.push(child);
      } else if (headerTitle) {
        acc.columnOptions.push(headerTitle);

        if (!hiddenCols?.includes(headerTitle)) {
          acc.columns.push(child);
          acc.showColumns.push(headerTitle);
        }
      } else {
        acc.columns.push(child);
      }

      return acc;
    },
    { columns: [], showColumns: [], columnOptions: [] }
  );

  return data;
};

type useHideColumnsArgs = {
  children: React.ReactNode;
  id?: string;
  persistHiddenColumns?: boolean;
  alwaysHiddenColumns?: string[];
  nonHideableColumns?: string[];
  headerColumnGroup?: React.ReactNode;
  defaultValues?: {
    showColumns: string[];
    columnOptions: string[];
  };
  afterColumnsUpdate?: (showColumns: string[], columnOptions: string[]) => void;
};

const useHideColumns = ({
  children,
  id,
  persistHiddenColumns,
  alwaysHiddenColumns,
  nonHideableColumns,
  headerColumnGroup,
  afterColumnsUpdate,
  defaultValues,
}: useHideColumnsArgs) => {
  const [cookies, setCookie] = useCookies([`table-columns-${id}`]);
  const initialState = getColumnsData(
    children,
    alwaysHiddenColumns,
    nonHideableColumns,
    defaultValues
      ? getHiddenColumns(defaultValues.columnOptions, defaultValues.showColumns)
      : persistHiddenColumns
      ? getHiddenColumns(
          cookies[`table-columns-${id}`]?.columnOptions ?? [],
          cookies[`table-columns-${id}`]?.showColumns ?? []
        )
      : []
  );
  const [columns, setColumns] = useState<React.ReactNode>(initialState.columns);
  const [showColumns, setShowColumns] = useState<string[]>(
    defaultValues?.showColumns ??
      (persistHiddenColumns
        ? cookies[`table-columns-${id}`]?.showColumns ??
          initialState.showColumns
        : initialState.showColumns)
  );
  const [columnOptions, setColumnOptions] = useState<string[]>(
    defaultValues?.columnOptions ??
      (persistHiddenColumns
        ? cookies[`table-columns-${id}`]?.columnOptions ??
          initialState.columnOptions
        : initialState.columnOptions)
  );
  const [headerColumns, setHeaderColumns] = useState<
    React.ReactNode | undefined
  >(headerColumnGroup);
  const currentChildren = useRef<React.ReactNode>([]);

  const updateVisibleColumns = (visibleColumns: string[]) => {
    setShowColumns(visibleColumns);
    if (persistHiddenColumns) {
      setCookie(`table-columns-${id}`, {
        showColumns: visibleColumns,
        columnOptions: columnOptions,
      });
    }

    if (afterColumnsUpdate) {
      afterColumnsUpdate(visibleColumns, columnOptions);
    }

    if (Array.isArray(children)) {
      const columnsToShow = children.filter((child) => {
        const headerText = getText(child.props?.header ?? '');
        return (
          !child.props?.header ||
          nonHideableColumns?.includes(headerText) ||
          visibleColumns.includes(headerText) ||
          !headerText
        );
      });

      setColumns(columnsToShow);

      if (headerColumnGroup) {
        const newHeaderColumns = getNewHeaderGroup(
          headerColumnGroup as JSX.Element,
          columnOptions,
          (header: string) => visibleColumns.includes(header)
        );

        setHeaderColumns(newHeaderColumns);
      }
    }
  };

  const hideColumn = (column: String) => {
    const newShowCols = showColumns.filter((col) => col !== column);

    setShowColumns(newShowCols);

    if (afterColumnsUpdate) {
      afterColumnsUpdate(newShowCols, columnOptions);
    }

    if (persistHiddenColumns) {
      setCookie(`table-columns-${id}`, {
        showColumns: newShowCols,
        columnOptions: columnOptions,
      });
    }

    if (Array.isArray(columns)) {
      const newCols = columns.filter(
        (col) => getText(col.props?.header ?? '') !== column
      );
      setColumns(newCols);
    }

    if (headerColumns) {
      const newHeaderColumns = getNewHeaderGroup(
        headerColumns as JSX.Element,
        columnOptions,
        (title: string) => column !== title
      );

      setHeaderColumns(newHeaderColumns);
    }
  };

  useEffect(() => {
    if (Array.isArray(children) && currentChildren.current !== children) {
      currentChildren.current = children;
      const hiddenCols = getHiddenColumns(columnOptions, showColumns);

      const headers = getColumnsData(
        children,
        alwaysHiddenColumns,
        nonHideableColumns,
        hiddenCols
      );

      setColumnOptions(headers.columnOptions);
      setShowColumns(headers.showColumns);
      setColumns(headers.columns);
    }
  }, [showColumns, columnOptions, children, alwaysHiddenColumns]);

  return {
    updateVisibleColumns,
    hideColumn,
    columns,
    headerColumns,
    columnOptions,
    showColumns,
  };
};

export default useHideColumns;
