import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import {
  downloadFile,
  getFilesTotalSize,
  FILE_FORMATS,
  transformBToMB,
} from 'apps/tmr-frontend/src/utils/fileUtil';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import {
  fetchPayrollBonusAttachment,
  fetchPCReimbursementEmpAttachment,
} from 'apps/tmr-frontend/src/app/services/PayrollService';
import ErrorToast, {
  showToast,
} from 'apps/tmr-frontend/src/app/components/messages/ErrorAlert';
import MultipleFileInput from 'apps/tmr-frontend/src/app/components/inputs/StyledInputs/MultipleFileInput';

type PCReimbursementFileProps = {
  prevAttachments?: { name: string; uniqueName: string; size: number }[];
  attachments?: File[];
  totalLimit: number;
  onChange: (files: File[] | null) => void;
  empID: string;
  draftID?: string;
  deleteAttachment: (fileName: string, size: number) => void;
  useBonus?: boolean;
  defaultRemovedAttachments?: string[];
};

const PCReimbursementFile = ({
  prevAttachments,
  attachments,
  totalLimit,
  onChange,
  empID,
  draftID,
  deleteAttachment,
  useBonus,
  defaultRemovedAttachments,
}: PCReimbursementFileProps) => {
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const [attachmentName, setAttachmentName] = useState('');
  const [fetchFile, setFetchFile] = useState(false);
  const [storedAttachments, setStoredAttachments] = useState(
    prevAttachments?.filter(
      (attachment) =>
        !defaultRemovedAttachments?.includes(attachment.uniqueName)
    )
  );
  const toast = useRef<Toast>(null);

  const { isFetching } = useQuery({
    queryKey: ['getEmployeeAttachment', draftID, empID, attachmentName],
    queryFn: (options) => {
      if (useBonus) {
        return fetchPayrollBonusAttachment(
          draftID || '',
          empID,
          attachmentName,
          options.signal
        );
      }

      return fetchPCReimbursementEmpAttachment(
        draftID || '',
        empID,
        attachmentName,
        options.signal
      );
    },
    onSuccess: (response) => {
      setFetchFile(false);
      downloadFile(response);
    },
    onError: () => {
      setFetchFile(false);
      showToast(
        'error',
        toast,
        'Employee Attachment',
        "The attachment couldn't be downloaded",
        3000
      );
    },
    refetchOnWindowFocus: false,
    enabled: fetchFile,
  });

  const downloadAttachment = (attachment: {
    name: string;
    uniqueName: string;
  }) => {
    setAttachmentName(attachment.uniqueName);
    setFetchFile(true);
  };

  const remove = (fileName: string, size: number) => {
    deleteAttachment(fileName, size);
    const remainingFiles = storedAttachments?.filter(
      (attachment) => attachment.uniqueName !== fileName
    );
    setStoredAttachments(remainingFiles);
  };

  useEffect(() => {
    setStoredAttachments(
      prevAttachments?.filter(
        (attachment) =>
          !defaultRemovedAttachments?.includes(attachment.uniqueName)
      )
    );
  }, [prevAttachments]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <i
        onClick={() => {
          setShowNoteDialog(true);
        }}
        className={`pi ${
          storedAttachments?.length || attachments ? 'pi-file-edit' : 'pi-file'
        } text-17px totalColor cursor-pointer`}
      />
      <Dialog
        header="Backup Attachment"
        visible={showNoteDialog}
        onHide={() => {
          setShowNoteDialog(false);
        }}
      >
        <div>
          {!!storedAttachments?.length && (
            <div>
              <div className="flex">
                The current
                {storedAttachments.length > 1
                  ? ' attachments are'
                  : ' attachment is'}
                :
                <div className="flex flex-column gap-2">
                  {storedAttachments.map((prevAttachment) => (
                    <div>
                      {isFetching &&
                      attachmentName === prevAttachment.uniqueName ? (
                        <ProgressSpinner className="w-1rem h-auto ml-4" />
                      ) : (
                        <span className="flex">
                          <span
                            className="ml-1 totalColor cursor-pointer mr-2"
                            onClick={() => downloadAttachment(prevAttachment)}
                          >
                            {prevAttachment.name}
                          </span>
                          <i
                            onClick={() => {
                              remove(
                                prevAttachment.uniqueName,
                                prevAttachment.size
                              );
                            }}
                            className={`pi pi-ban errorText cursor-pointer my-auto`}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2 mb-1">
                To add new attachments select them below:
              </div>
            </div>
          )}
          <MultipleFileInput
            value={attachments ?? []}
            onChange={(files) => {
              onChange(files);
            }}
            acceptedFormats={FILE_FORMATS}
            totalLimit={totalLimit + getFilesTotalSize(attachments ?? [])}
            totalLimitLabel={`${transformBToMB(
              totalLimit + getFilesTotalSize(attachments ?? [])
            )} MB`}
            emptyMessage={`Upload new attachments. Allowed extensions: ${FILE_FORMATS.join(
              ', '
            )}.`}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default PCReimbursementFile;
