import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../context/CompanyContext';
import { fecthLatestJobCostBudget } from '../../services/JobCostBudget';
import JobCostBudgetTable from '../jobCostBudget/JobCostBudgetTable';
import JobCostBudgetReportHeader from '../jobCostBudget/JobCostBudgetReportHeader';
import JobCostBudgetReport from '../jobCostBudget/JobCostBudgetReport';

const DashboardJobCostBudget = () => {
  const { selectedCompany } = useCompanyContext();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const { data, isError } = useQuery({
    queryKey: ['getLatestJobCostBudget', selectedCompany],
    queryFn: ({ signal }) =>
      fecthLatestJobCostBudget(
        selectedCompany!.id,
        selectedCompany!.source,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  if (isError) {
    return (
      <ResumeCard>
        <div className="relative p-5 px-4 flex flex-column w-full">
          <Link to={`/accounting-dashboard/jobs-contracts`} className="w-fit">
            <div className="text-24px font-bold cursor-pointer totalColor hover:underline w-fit">
              Job Cost Project Budget
            </div>
          </Link>
          <div className="my-8 py-3">
            <ErrorMessage
              content={'Failed to obtain data! Please try again later.'}
            />
          </div>
        </div>
      </ResumeCard>
    );
  }

  return (
    <ResumeCard>
      <div className="p-3 px-4 flex flex-column">
        <div className="flex justify-content-between">
          <div>
            <div className="text-24px font-bold totalColor">
              Job Cost Project Budget
            </div>
            <div className="pt-2 text-17px">
              {`${data.periodStart ?? ''}${
                data.periodEnd ? ` - ${data.periodEnd}` : ''
              }`}
            </div>
          </div>
          <JobCostBudgetReport elementRef={tableRef} jobCost={data} />
        </div>
        <div className="pt-2 printBackground pdfDivFitContent" ref={tableRef}>
          <JobCostBudgetReportHeader jobCost={data} />
          <JobCostBudgetTable details={data.details} defaultSize={430} />
        </div>
      </div>
    </ResumeCard>
  );
};

export default DashboardJobCostBudget;
