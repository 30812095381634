import {
  Dropdown,
  DropdownItemTemplateType,
  DropdownProps,
} from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { forwardRef } from 'react';

export type DropdownStyledProps = DropdownProps & {
  isDisabled?: boolean;
  options: unknown[];
  isLoading?: boolean;
  labelField?: string;
  clearable?: boolean;
  valueField?: string;
  id?: string;
  error?: boolean;
  isTitle?: boolean;
  absoluteLabel?: boolean;
};

const DropdownStyled = forwardRef<Dropdown, DropdownStyledProps>(
  (
    {
      isDisabled = false,
      options,
      isLoading = false,
      labelField,
      valueField,
      clearable = true,
      id,
      error,
      isTitle,
      absoluteLabel,
      ...otherProps
    }: DropdownStyledProps,
    ref
  ) => {
    const selectedOptionTemplate = (
      option: DropdownItemTemplateType,
      props: DropdownProps
    ) => {
      const titleStyle = 'text-base lg:text-4xl navbarTitle';
      const absoluteStyle = 'absolute left-0 right-0';

      if (option) {
        return (
          <div className="relative">
            <div
              className={
                isTitle
                  ? titleStyle
                  : `${
                      absoluteLabel ? absoluteStyle : ''
                    } blackText text-standard overflow-hidden white-space-nowrap text-overflow-clip`
              }
            >
              {option[labelField as keyof DropdownItemTemplateType] || option}
            </div>
          </div>
        );
      }
      return (
        <span className={isTitle ? titleStyle : 'text-standard'}>
          {props.placeholder}
        </span>
      );
    };

    const optionTemplate = (option: DropdownItemTemplateType) => {
      if (!option) {
        return null;
      }

      if (isLoading) {
        return (
          <div className="flex align-items-center p-2 disabled">
            <Skeleton height="1rem" />
          </div>
        );
      }

      return (
        <div className="blackText text-standard">
          {option[labelField as keyof DropdownItemTemplateType] || option}
        </div>
      );
    };

    return (
      <Dropdown
        inputId={id}
        disabled={isDisabled}
        optionLabel={labelField}
        optionValue={valueField}
        options={
          isLoading
            ? [{ disabled: true }, { disabled: true }, { disabled: true }]
            : options
        }
        showClear={clearable}
        className={`fieldBorder ${error && 'p-invalid'} ${
          isTitle && 'border-transparent'
        }`}
        itemTemplate={optionTemplate}
        valueTemplate={selectedOptionTemplate}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export default DropdownStyled;
