import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTableRowMouseEventParams } from 'primereact/datatable';
import Table from '../../components/Table/Table';
import { animateCellText } from 'apps/tmr-frontend/src/utils/htmlUtils';
import { jobCostBudgetDetail } from '../../Interfaces/Accounting/JobCostBudget.interface';
import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';

type jobCostBudgetTableProps = {
  details: jobCostBudgetDetail[];
  defaultSize?: number;
};

const jobCostBudgetTable = React.forwardRef<
  HTMLDivElement,
  jobCostBudgetTableProps
>(({ details, defaultSize }, ref) => {
  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  let originalBudget = 0;
  let budgetAdjustment = 0;
  let revisedBudget = 0;
  let previousCost = 0;
  let mtdActual = 0;
  let costToDateActual = 0;
  let costToComplete = 0;
  details?.forEach((detail) => {
    originalBudget += detail.originalBudget;
    budgetAdjustment += detail.budgetAdjustment;
    revisedBudget += detail.revisedBudget;
    previousCost += detail.previousCost;
    mtdActual += detail.mtdActual;
    costToDateActual += detail.costToDateActual;
    costToComplete += detail.costToComplete;
  });

  return (
    <React.Fragment>
      <Table
        id="job-cost-budget-table"
        ref={ref}
        data={details}
        className={`dashboardOptionShadow w-full`}
        onRowMouseEnter={useCallback(onRowHover, [])}
        fixedDefaultHeight={defaultSize ?? 260}
      >
        <Column
          field="categoryCode"
          header="Category Code"
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          footer="Grand Total"
        />
        <Column
          field="categoryDescription"
          header="Category"
          body={(jb) => {
            return <div className="scroll-text">{jb.categoryDescription}</div>;
          }}
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter block text-center border-top-2 mt-4"
        />
        <Column
          field="originalBudget"
          header="Original Budget"
          body={(jc) => formatCurrency(jc.originalBudget)}
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(originalBudget)}
        />
        <Column
          field="budgetAdjustment"
          header="Budget Adjustment"
          body={(jc) => formatCurrency(jc.budgetAdjustment)}
          style={{ minWidth: '145px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(budgetAdjustment)}
        />
        <Column
          field="revisedBudget"
          header="Revised Budget"
          body={(jc) => formatCurrency(jc.revisedBudget)}
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(revisedBudget)}
        />
        <Column
          field="previousCost"
          header="Previous Costs"
          body={(jc) => formatCurrency(jc.previousCost)}
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(previousCost)}
        />
        <Column
          field="mtdActual"
          header="MTD Actual"
          body={(jc) => formatCurrency(jc.mtdActual)}
          style={{ minWidth: '125px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(mtdActual)}
        />
        <Column
          field="costToDateActual"
          header="Cost To Date Actual"
          body={(jc) => formatCurrency(jc.costToDateActual)}
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(costToDateActual)}
        />
        <Column
          field="costToComplete"
          header="Cost To Complete"
          body={(jc) => formatCurrency(jc.costToComplete)}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(costToComplete)}
        />
      </Table>
    </React.Fragment>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<jobCostBudgetTableProps>,
  nextTransactions: Readonly<jobCostBudgetTableProps>
) => {
  return prevTransactions.details === nextTransactions.details;
};

export default React.memo(jobCostBudgetTable, transactionsAreEqual);
