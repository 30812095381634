import React from 'react';
import * as XLSX from 'xlsx';
import {
  excelCleanTable,
  excelFormatNestedRows,
} from 'apps/tmr-frontend/src/utils/excelUtils';
import ReportButton from '../../components/report/ReportButton';
import { BASE_PDF_STYLES } from 'apps/tmr-frontend/src/utils/reportUtils';
import { useCompanyContext } from '../../context/CompanyContext';
import { JobCostBudget } from '../../Interfaces/Accounting/JobCostBudget.interface';

type JobCostBudgetReportProps = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  jobCost: JobCostBudget;
};

const JobCostBudgetReport = ({
  elementRef,
  jobCost,
}: JobCostBudgetReportProps) => {
  const { selectedCompany } = useCompanyContext();
  const reportDate = new Date();

  const excelElementPreProcess = (element: HTMLElement) => {
    excelCleanTable(element);

    return element;
  };

  const excelBeforeDownload = (workBook: XLSX.WorkBook) => {
    const sheet = workBook.Sheets['Sheet1'];
    const companyName = selectedCompany!.name;

    sheet['C2'] = { t: 's', v: companyName };
    sheet['C3'] = { t: 's', v: 'Job Cost Project Budget' };

    sheet[`A6`] = { t: 's', v: 'Period:' };
    sheet[`B6`] = {
      t: 's',
      v: `${jobCost.periodStart ?? ''}${
        jobCost.periodEnd ? ` - ${jobCost.periodEnd}` : ''
      }`,
    };

    sheet[`A7`] = { t: 's', v: 'Created at:' };
    sheet[`B7`] = { t: 's', v: reportDate.toLocaleString() };
  };

  return (
    <ReportButton
      elementToPrintRef={elementRef}
      fileName="Job_Cost_Project_Budget"
      pdfStyles={BASE_PDF_STYLES}
      excelPreprocess={excelElementPreProcess}
      excelBeforeDownload={excelBeforeDownload}
      tableOriginCell="A10"
      widthElementID="job-cost-budget-table"
    />
  );
};

export default JobCostBudgetReport;
