import {
  AgedPayable,
  Payable,
} from '../../Interfaces/Accounting/AccountsPayables.interfaces';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';

import {
  fetchOpenVendors,
  fetchUnapprovedVendors,
  fetchVendors,
} from '../../services/vendorsService';

export function getVendorFetch(
  reportType: string,
  company: Company,
  signal: AbortSignal | undefined
) {
  switch (reportType) {
    case 'openPayables':
      return fetchOpenVendors(company, signal);
    case 'agedPayables':
      return fetchOpenVendors(company, signal);
    case 'unapprovedInvoices':
      return fetchUnapprovedVendors(company, signal);
    case 'openPayablesPerJob':
      return fetchOpenVendors(company, signal, true);
    default:
      return fetchVendors({ company }, signal);
  }
}

export function processPayableList(data: Payable[]) {
  const newData: Payable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = { gross: 0, amount: 0, tax: 0, discount: 0, net: 0 };

  data.forEach((payable: Payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.gross += payable.Gross;
      acumulator.amount += payable.PayableAmount ?? 0;
      acumulator.tax += payable.Tax;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount + (payable.Retainage ?? 0);
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        gross: payable.Gross,
        amount: payable.PayableAmount ?? 0,
        tax: payable.Tax,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount + (payable.Retainage ?? 0),
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);
    }
  });

  return newData;
}

export function processPayableListPerJob(data: Payable[]) {
  const newData: Payable[] = [];
  let currentJob = data[0]?.Job;
  let currentJobName = data[0]?.Description;
  let acumulator = { gross: 0, discount: 0, net: 0, retainage: 0 };

  data.forEach((payable: Payable, idx: number) => {
    if (currentJob === payable.Job) {
      acumulator.gross += payable.Gross;
      acumulator.retainage += payable.Retainage ?? 0;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount + (payable.Retainage ?? 0);
    } else {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);

      currentJob = payable.Job;
      currentJobName = payable.Description;
      acumulator = {
        gross: payable.Gross,
        retainage: payable.Retainage ?? 0,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount + (payable.Retainage ?? 0),
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
      } as Payable);
    }
  });

  return newData;
}

export function processAgedPayablePerVendor(data: AgedPayable[]) {
  const newData: AgedPayable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = { current: 0, over30: 0, over60: 0, over90: 0, holdRet: 0 };

  data.forEach((payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.current += payable.Current;
      acumulator.over30 += payable.Over30;
      acumulator.over60 += payable.Over60;
      acumulator.over90 += payable.Over90;
      acumulator.holdRet += payable.HoldRet;
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        current: payable.Current,
        over30: payable.Over30,
        over60: payable.Over60,
        over90: payable.Over90,
        holdRet: payable.HoldRet,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);
    }
  });

  return newData;
}

export const getVendorKeyPerCompany = (companySource: CompanySource) => {
  if (companySource === CompanySource.ViewPoint) {
    return 'code';
  }

  return 'id';
};
