import React, { useContext, useState } from 'react';

const DefaultContext = React.createContext(
  {} as { useDefault: boolean; setUseDefault: (value: boolean) => void }
);

type DefaultContextProviderProps = {
  children: React.ReactNode;
};

export const UseDefaultContextProvider = ({
  children,
}: DefaultContextProviderProps) => {
  const [useDefault, setUseDefault] = useState<boolean>(false);
  const contextValue = { useDefault, setUseDefault };

  return (
    <DefaultContext.Provider value={contextValue}>
      {children}
    </DefaultContext.Provider>
  );
};

export const UseDefaultContext = () => useContext(DefaultContext);
