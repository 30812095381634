import React, { useCallback, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { nextSortState, sortByField } from '../../../../utils/sortUtils';
import {
  Payable,
  Payment,
  PaymentBatch,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import APDrilldown from '../APDrilldown/APDrilldown';
import { animateCellText } from '../../../../utils/htmlUtils';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { postPaymentBatch } from '../../../services/AccountsPayablesService';
import LoadingButton from '../../../components/inputs/LoadingButton';
import { Toast } from 'primereact/toast';
import { useCompanyContext } from '../../../context/CompanyContext';

export type APOpenTableProps = {
  accountsPayables: Payable[];
  isArchive?: boolean;
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
};

const APOpenTable = React.forwardRef<HTMLDivElement, APOpenTableProps>(
  ({ accountsPayables, isArchive, createBatch, changeCreateBatch }, ref) => {
    const { selectedCompany } = useCompanyContext();
    const [selectedPayables, setSelectedPayables] = useState<Payment[]>([]);
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [sortState, setSortState] = React.useState<{
      field: string;
      order: DataTableSortOrderType;
    }>({ field: '', order: null });
    const [sortedTransactions, setSortedTransactions] = React.useState([
      ...accountsPayables,
    ]);
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();
    const toast = useRef<Toast>(null);

    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let payableAmountTotal = 0;
    let taxTotal = 0;
    let discTotal = 0;
    let gross = 0;
    accountsPayables.forEach((payable: Payable) => {
      if (payable.Reference) {
        payableAmountTotal += payable.PayableAmount ?? 0;
        taxTotal += payable.Tax ?? 0;
        discTotal += payable.DiscountOffered ?? 0;
        gross += payable.Gross;
      }
    });

    const onRowClick = (e: DataTableRowClickEventParams) => {
      if (e.data.Reference) {
        setSelectedPayable(e.data);
        setShowDrilldown(true);
      }
    };

    const rowClassName = (data: Payable) => {
      let className = data.Reference ? 'cursor-pointer' : '';
      if (!data.Reference) {
        className = `${className} font-bold surface-300`;
      }

      return className;
    };

    const handleSort = (event: DataTablePFSEvent) => {
      const { reset, field, order } = nextSortState(
        event.sortField,
        sortState.field
      );
      setSortState({ field, order });

      if (reset) {
        setSortedTransactions([...accountsPayables]);
        return;
      }

      const sortedData = [...accountsPayables].sort((a, b) => {
        switch (event.sortField) {
          case 'Net': {
            const aValue = a.NetAmount + (a.Retainage ?? 0);
            const bValue = b.NetAmount + (b.Retainage ?? 0);
            return (order ?? 0) * (aValue - bValue);
          }
          default: {
            const key = event.sortField as keyof Payable;
            const aValue = a[key];
            const bValue = b[key];
            return sortByField(aValue, bValue, order ?? 0);
          }
        }
      });

      setSortedTransactions(sortedData);
    };

    const removePayable = (payable: Payment) => {
      const newList = selectedPayables.filter(
        (payment) =>
          !(
            payment.apref === payable.apref &&
            payment.aptrans === payable.aptrans &&
            payment.vendor === payable.vendor &&
            payment.payType === payable.payType
          ) && !(payment.vendor === payable.vendor && !payment.apref)
      );
      setSelectedPayables(newList);
      setPaymentTotal((amount) => amount - payable.amount);
    };

    const addPayables = (
      payable: Payment,
      vendorAmount: number,
      vendorTotal: Payment
    ) => {
      setSelectedPayables((list) => {
        const currentVendorAmount = list.filter(
          (current) => current.vendor === payable.vendor
        ).length;
        const newList = [...list, payable];

        if (currentVendorAmount === vendorAmount) {
          newList.push(vendorTotal);
        }

        return newList;
      });
      setPaymentTotal((amount) => amount + payable.amount);
    };

    const { mutate, isLoading } = useMutation({
      mutationFn: (batch: Payment[]) => {
        return postPaymentBatch(
          selectedCompany!,
          batch.reduce((acc, value) => {
            if (value.apref) {
              const { amount, ...otherFields } = value;
              acc.push(otherFields);
            }

            return acc;
          }, [] as PaymentBatch[])
        );
      },
      onSuccess: () => {
        setPaymentTotal(0);
        setSelectedPayables([]);
        if (changeCreateBatch) {
          changeCreateBatch(false);
        }

        showToast(
          'success',
          toast,
          'Create Payment Batch',
          'The Payment Batch was created successfully!',
          3000
        );
      },
      onError: (error: AxiosError) => {
        const response = error.response;
        const data = response?.data as { message: string };
        showToast(
          'error',
          toast,
          'Create Payment Batch Error',
          data.message || 'An error ocurred! please try again later',
          3000
        );
      },
    });

    return (
      <div>
        <ErrorToast toastRef={toast} />
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
            extendedInfo={isArchive}
          />
        )}
        <Table
          id="open-table"
          ref={ref}
          data={sortedTransactions}
          className={`mx-3 dashboardOptionShadow tableFirstLineStyled dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowClick={useCallback(onRowClick, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          sortField={sortState.field}
          sortOrder={sortState.order}
          onSort={useCallback(handleSort, [sortState.field, accountsPayables])}
          hideColumns={true}
          selection={selectedPayables}
        >
          <Column
            field="Vendor"
            header="Vendor"
            body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
            headerClassName={`tableHeader font-normal justify-content-center`}
            style={{ minWidth: '300px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Reference"
            header="Inv. Number"
            style={{ minWidth: '150px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell text-center word-break-all"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.InvoiceDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            style={{ minWidth: '230px' }}
            body={(ap) => <div className="scroll-text">{ap.Description}</div>}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName={`tableFooter`}
            sortable
          />
          <Column
            field="DueDate"
            header="Due Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.DueDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive &&
              'block text-right border-top-2 border-transparent mt-4 limitBorder relative'
            }`}
            footer={isArchive && 'Total:'}
            sortable
          />
          <Column
            field="Gross"
            header="Gross"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Gross)}
            headerClassName="tableHeader font-normal justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            footer={isArchive && formatCurrency(gross)}
            sortable
          />
          <Column
            field="HoldCode"
            header="Hold Code"
            style={{ minWidth: '80px', maxWidth: '80px' }}
            body={(ap) => <div className="scroll-text">{ap.HoldCode}</div>}
            headerClassName="tableHeader font-normal justify-content-center text-center overflow-x-visible white-space-normal"
            className="text-standard blackText tableCell overflow-x-hidden whiteSpace-nowrap checkOverflow"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            sortable
          />
          {!isArchive && (
            <Column
              field="PayType"
              header="Pay Type"
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName="tableHeader font-normal"
              className="justify-content-center text-standard blackText tableCell"
              footer="Total:"
              footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
              sortable
            />
          )}
          <Column
            field="PayableAmount"
            header={'Payable Amount'}
            style={{ minWidth: '130px' }}
            body={(ap) => formatCurrency(ap.PayableAmount)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="Tax"
            header="Tax"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Tax)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(taxTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="DiscountOffered"
            header="Discount Offered"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.DiscountOffered)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="NetAmount"
            header={'Net Amount'}
            style={{ minWidth: '140px' }}
            body={(ap) => formatCurrency(ap.NetAmount + (ap.Retainage ?? 0))}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal + taxTotal - discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {createBatch && (
            <Column
              header="Select"
              body={(ap, options) => {
                return (
                  <div className="capitalize">
                    <Checkbox
                      checked={selectedPayables.some(
                        (payment) =>
                          payment.apref === ap.Reference &&
                          payment.aptrans === ap.TransCode &&
                          payment.vendor === ap.VendorCode &&
                          payment.payType === ap.PayType
                      )}
                      onChange={(e) => {
                        e.originalEvent.stopPropagation();
                        const payment = {
                          apref: ap.Reference,
                          aptrans: ap.TransCode,
                          vendor: ap.VendorCode,
                          payType: ap.PayType,
                          amount: ap.NetAmount + (ap.Retainage ?? 0),
                        };

                        if (e.checked) {
                          if (payment.apref) {
                            const vendorList = options.props.value.filter(
                              (value: Payable) => {
                                return payment.vendor === value.VendorCode;
                              }
                            );
                            const vendorTotalRow =
                              vendorList[vendorList.length - 1];
                            const vendorPayment = {
                              apref: vendorTotalRow.Reference,
                              aptrans: vendorTotalRow.TransCode,
                              vendor: vendorTotalRow.VendorCode,
                              payType: vendorTotalRow.PayType,
                              amount:
                                vendorTotalRow.NetAmount +
                                (vendorTotalRow.Retainage ?? 0),
                            };

                            addPayables(
                              payment,
                              vendorList.length - 2,
                              vendorPayment
                            );
                          } else {
                            const currentVendorJson = selectedPayables.reduce(
                              (acc, current) => {
                                if (current.vendor === payment.vendor) {
                                  acc[
                                    `${current.vendor}-${current.apref}-${current.payType}`
                                  ] = true;
                                }

                                return acc;
                              },
                              {} as Record<string, boolean>
                            );
                            const vendorPayments: any[] = [];
                            let total = 0;
                            options.props.value.forEach((value: Payable) => {
                              if (
                                value.VendorCode === payment.vendor &&
                                value.Reference &&
                                !currentVendorJson[
                                  `${value.VendorCode}-${value.Reference}-${value.PayType}`
                                ]
                              ) {
                                const vendorPayment = {
                                  apref: value.Reference,
                                  aptrans: value.TransCode ?? 0,
                                  vendor: value.VendorCode,
                                  payType: value.PayType ?? 0,
                                  amount:
                                    value.NetAmount + (value.Retainage ?? 0),
                                };
                                vendorPayments.push(vendorPayment);

                                total += vendorPayment.amount;
                              }
                            });

                            setPaymentTotal((current) => current + total);
                            setSelectedPayables((list) => [
                              ...list,
                              ...vendorPayments,
                              payment,
                            ]);
                          }
                        } else {
                          if (payment.apref) {
                            removePayable(payment);
                          } else {
                            let total = 0;
                            setSelectedPayables((currentList) => {
                              const newList = currentList.filter((current) => {
                                if (current.vendor === payment.vendor) {
                                  total += current.apref ? current.amount : 0;

                                  return false;
                                }

                                return true;
                              });
                              return newList;
                            });
                            setPaymentTotal((current) => current - total);
                          }
                        }
                      }}
                    />
                  </div>
                );
              }}
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName={`tableHeader font-normal`}
              className={`justify-content-center text-standard blackText tableCell`}
              footerClassName="tableFooter"
            />
          )}
        </Table>
        {createBatch && (
          <div className="ml-auto w-full px-3 sm:px-0 sm:w-24rem mr-5 my-5 text-standard">
            <div className="flex justify-content-between mt-1">
              <span className="font-bold">Payment Batch Total</span>
              <span>{formatCurrency(paymentTotal)}</span>
            </div>
            <LoadingButton
              isLoading={isLoading}
              label="Submit"
              disabled={!selectedPayables.length}
              className="flex ml-auto buttonBackground border-0 text-xl w-fit mt-3 px-3 radius-10px"
              onClick={() => mutate(selectedPayables)}
            />
          </div>
        )}
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APOpenTableProps>,
  nextTransactions: Readonly<APOpenTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APOpenTable, transactionsAreEqual);
