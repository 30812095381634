import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef } from 'react';

type TableContextMenuProps = {
  dt: React.RefObject<DataTable>;
  afterContextMenuSelected: (header: string) => void;
  noMenuColumns?: string[];
};

const TableContextMenu = ({
  noMenuColumns,
  dt,
  afterContextMenuSelected,
}: TableContextMenuProps) => {
  const cm = useRef<ContextMenu>(null);
  const items = [
    {
      label: 'Hide',
      icon: 'pi pi-eye-slash',
      command: (event: any) => {},
    },
  ];

  const handleContextMenu = (event: MouseEvent, header: string) => {
    items[0].command = () => {
      afterContextMenuSelected(header);
    };

    cm.current?.show(event as unknown as React.SyntheticEvent<Element, Event>);
  };

  useEffect(() => {
    if (dt.current && cm.current) {
      const domEl = dt.current.getElement();

      const tableHead = domEl.querySelector('thead');
      const thElements = tableHead?.querySelectorAll('th') || [];

      const functionsJson: Record<string, (e: any) => void> = {};

      thElements.forEach((th) => {
        const headerText = th.querySelector('.p-column-title');
        const header = headerText?.textContent;

        if (header && noMenuColumns?.includes(header)) {
          return;
        }

        if (header) {
          const wrappedHandleContextMenu = (e: MouseEvent) =>
            handleContextMenu(e, header);

          th.addEventListener('contextmenu', wrappedHandleContextMenu);
          functionsJson[header] = wrappedHandleContextMenu;
        }
      });

      return () => {
        thElements.forEach((th) => {
          const headerText = th.querySelector('.p-column-title');
          const header = headerText?.textContent;

          if (header && noMenuColumns?.includes(header)) {
            return;
          }

          if (header) {
            const menuFunction = functionsJson[header];
            th.removeEventListener('contextmenu', menuFunction);
          }
        });
      };
    }

    return;
  }, [dt.current]);

  return <ContextMenu model={items} ref={cm} />;
};

export default TableContextMenu;
