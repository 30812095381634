import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from '../../hooks/useFiltersCache';
import { APFilterFields } from '../../Interfaces/Accounting/AccountsPayables.interfaces';
import { fetchAccountsPayablesFilters } from './APFilters';

const formatFilters = (filters: fetchAccountsPayablesFilters) => {
  return {
    ...filters,
    dates: filters.dates
      ? filters.dates.map((date) => date?.toString() || null)
      : null,
  };
};

type APParamsProps = {
  changeCreateBatch?: (data: boolean) => void;
};

const APParams = ({ changeCreateBatch }: APParamsProps) => {
  const [cookies] = useCookies(['filters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<fetchAccountsPayablesFilters>();
  const filters = useWatch<fetchAccountsPayablesFilters>();
  useFiltersCache({
    tab: 'AP',
    filters: formatFilters({
      ...filters,
      reportType: filters.reportType ?? '',
    }),
  });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const reportType = searchParams.get('reportType') || '';
    const account = searchParams.get('account') ?? '';
    const paymentBatch = searchParams.get('paymentBatch') ?? null;

    if (account) {
      setValue('account', account);
      setSearchParams();
      setRendered(true);
    }

    if (paymentBatch && changeCreateBatch) {
      changeCreateBatch(paymentBatch === 'true');
      setSearchParams();
    }

    if (reportType) {
      setValue('reportType', reportType);
      setSearchParams();
      setRendered(true);
    } else if (!rendered && cookies.filters?.tab === 'AP') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;

      if (cookieFilters.dates) {
        const dates = cookieFilters.dates;
        const start = new Date(dates[0]);
        const end = dates[1] ? new Date(dates[1]) : null;
        cookieFilters.dates = [start, end];
      }

      for (const i in cookieFilters) {
        setValue(i as APFilterFields, cookieFilters[i]);
      }
    }
  }, [
    setValue,
    searchParams,
    setSearchParams,
    rendered,
    setRendered,
    cookies.filters,
  ]);

  return <span />;
};

export default APParams;
