import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import APOpenTable, { APOpenTableProps } from './APOpenTable';
import { useFormContext } from 'react-hook-form';
import { fetchAccountsPayablesData } from '../../../services/AccountsPayablesService';
import APUnapprovedTable, { APUnapprovedTableProps } from './APUnapprovedTable';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { processPayableList } from '../apUtil';
import checkPermissions from '../../../HOCs/CheckPermissions';
import APYardiOpenTable from './APYardiOpenTable';
import { CompanySource } from '../../../Interfaces/User.interfaces';

type APOpenTableContainerProps = {
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
};

const APOpenTableContainer = React.forwardRef<
  HTMLDivElement,
  APOpenTableContainerProps
>(({ createBatch, changeCreateBatch }, ref) => {
  const { selectedCompany } = useCompanyContext();
  const { watch } = useFormContext<fetchAccountsPayablesFilters>();
  const filters = watch();
  const enabled = !!selectedCompany;

  const Unapproved = checkPermissions<APUnapprovedTableProps>({
    sectionsToAccess: ['unapproved_invoices'],
    Children: APUnapprovedTable,
  });
  const OpenPayables = checkPermissions<APOpenTableProps>({
    sectionsToAccess: ['open_payables'],
    Children:
      selectedCompany?.source === CompanySource.ViewPoint
        ? APOpenTable
        : APYardiOpenTable,
  });

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getAPPayables', selectedCompany, filters],
    queryFn: ({ signal }) =>
      fetchAccountsPayablesData(
        { ...filters, company: selectedCompany! },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: enabled,
  });

  if (isFetching || !enabled)
    return (
      <div className="text-center mx-auto mt-3">
        <ProgressSpinner />
      </div>
    );

  if (isError)
    return (
      <ErrorMessage
        content={'Failed to obtain data! Please try again later.'}
      />
    );

  if (filters.reportType === 'unapprovedInvoices') {
    return <Unapproved accountsPayables={data} />;
  }

  return (
    <OpenPayables
      accountsPayables={processPayableList(data)}
      isArchive={false}
      createBatch={createBatch}
      changeCreateBatch={changeCreateBatch}
    />
  );
});

export default APOpenTableContainer;
