import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';

const rowsOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
];

type RowSelectionProps = {
  showRows: number;
  setShowRows: (val: number) => void;
  onRowsAmountChange?: (rows: number) => void;
};

const RowSelection = ({
  onRowsAmountChange,
  setShowRows,
  showRows,
}: RowSelectionProps) => {
  useEffect(() => {
    if (showRows) {
      let isAvailableOption = false;
      rowsOptions.forEach((option) => {
        if (option.value === showRows) {
          isAvailableOption = true;
        }
      });

      if (!isAvailableOption) {
        setShowRows(rowsOptions[2].value);
      }
    }
  }, [showRows]);

  useEffect(() => {
    if (onRowsAmountChange) {
      onRowsAmountChange(showRows);
    }
  }, [showRows, onRowsAmountChange]);

  return (
    <div className="ml-auto w-fit">
      <div className="mb-1 flex gap-2 align-items-center">
        <span className="font-bold">Rows</span>
        <Dropdown
          className="w-fit"
          value={showRows}
          onChange={(e) => setShowRows(e.value)}
          options={rowsOptions}
        />
      </div>
    </div>
  );
};

export default RowSelection;
